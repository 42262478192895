import React, { useCallback } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { Grid } from '@alfalab/core-components/grid';
import { IconButton } from '@alfalab/core-components/icon-button';
import { Link } from '@alfalab/core-components/link';
import { Typography } from '@alfalab/core-components/typography';
import { ArrowDownLineDownSIcon } from '@alfalab/icons-glyph/ArrowDownLineDownSIcon';
import { ArrowsCwCompactSIcon } from '@alfalab/icons-glyph/ArrowsCwCompactSIcon';

import { currentOrganizationIdSelector } from '#/src/store/organizations/selectors';
import { appliedFiltersSelector } from '#/src/store/registers/registers-filters/selectors';
import {
    changeStatePartyTin,
    changeStateSendModal,
    changeStateType,
} from '#/src/store/registers/send-register/action-creators';

import { ResultModal } from './result-modal';
import { SendRegisterModal } from './send-register-modal';
import { SuperstructureLink } from './superstructure-link';

import './send-register.css';

const cn = createCn('send-register');

export function SendRegister() {
    const dispatch = useDispatch();
    const { role } = useSelector(appliedFiltersSelector);
    const currentOrganizationId = useSelector(currentOrganizationIdSelector);

    const handleOpenSendModal = useCallback(() => {
        if (currentOrganizationId && role) {
            batch(() => {
                dispatch(changeStateSendModal(true));
                dispatch(changeStateType(role));
                dispatch(changeStatePartyTin(currentOrganizationId));
            });
        }
    }, [dispatch, role, currentOrganizationId]);

    return (
        <div className={cn()}>
            <Grid.Row align='middle'>
                <Grid.Col width='6'>
                    <Button
                        data-test-id='load-register-button'
                        view='primary'
                        size='xs'
                        className={cn('show-btn')}
                        onClick={handleOpenSendModal}
                    >
                        Загрузить реестр
                    </Button>
                </Grid.Col>
                <Grid.Col width='6' className={cn('download-tpl')}>
                    <div className={cn('tpl-links')}>
                        <Typography.Text view='secondary-large'>
                            <Link
                                view='secondary'
                                data-test-id='download-template-register-link'
                                leftAddons={<ArrowDownLineDownSIcon width='18px' height='18px' />}
                                href='/doc/шаблон реестра.xlsx'
                                download='шаблон реестра.xlsx'
                            >
                                Скачать шаблон реестра
                            </Link>
                        </Typography.Text>
                        <SuperstructureLink />
                    </div>
                </Grid.Col>
            </Grid.Row>
            <Typography.Text tag='div' color='secondary'>
                Файл должен быть в формате .xls или .txt.
            </Typography.Text>
            <Typography.Text tag='div' color='secondary'>
                Для отображения актуальных статусов обработки реестров в списке необходимо нажать{' '}
                <IconButton size='xxs' className={cn('reload-btn')} icon={ArrowsCwCompactSIcon} />
            </Typography.Text>
            <Gap size='m' />
            <SendRegisterModal />
            <ResultModal />
        </div>
    );
}
