import React from 'react';
import { createCn } from 'bem-react-classname';

import { Typography } from '@alfalab/core-components/typography';
import { ArrowDownLineDownSIcon } from '@alfalab/icons-glyph/ArrowDownLineDownSIcon';

import { DownloadFileLink } from '#/src/components/download-file-link';

import './superstructure-link.css';

const cn = createCn('superstructure-link');

export function SuperstructureLink() {
    return (
        <DownloadFileLink
            underline={false}
            dataTestId='superstructure-link'
            fileType='1С'
            fileName='1C надстройка.zip'
            needSpinner={false}
        >
            <div className={cn()}>
                <div className={cn('icon')}>
                    <ArrowDownLineDownSIcon width='18px' height='18px' />
                </div>
                <div className={cn('text')}>
                    <Typography.Text tag='div' view='secondary-large'>
                        Скачать 1C надстройку
                    </Typography.Text>
                </div>
            </div>
        </DownloadFileLink>
    );
}
